<template>
  <v-card flat>
    <v-card-title>
      {{ $t('message.thresholds') }}
    </v-card-title>
    <v-card-text>
      <div class="mb-3">
        {{ $t('message.thresholds_info') }}
      </div>

      <v-row class="mb-1">
        <v-col>
          <div class="caption">{{ $t('message.min_threshold') }}</div>
          <div>{{ observedProperty.minThreshold || $t('message.not_provided') }}</div>
        </v-col>
        <v-col>
          <div class="caption">{{ $t('message.max_threshold') }}</div>
          <div>{{ observedProperty.maxThreshold || $t('message.not_provided') }}</div>
        </v-col>
      </v-row>

      <div class="caption">{{ $t('message.notified_to') }}:</div>
      <div>
        {{ observedProperty.email || $t('message.email_notifications_not_enabled') }}
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn text color="primary" @click="updateDialog = true">{{ $t('message.edit') }}</v-btn>
      <ThresholdUpdate v-model="updateDialog" :observed-property="observedProperty" @reload="$emit('reload')" />
    </v-card-actions>
  </v-card>
</template>

<script>
import ThresholdUpdate from '@/components/ObservedProperty/Threshold/Update'
export default {
  name: 'ThresholdShow',
  components: { ThresholdUpdate },
  props: {
    observedProperty: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      updateDialog: false
    }
  }
}
</script>
