<template>
  <div class="d-flex flex-column grey lighten-5 fill-height">
    <AppToolbar />
    <template v-if="error">
      <PageNotFound v-if="'Not Found' === error" />
      <GenericError v-else />
    </template>
    <template v-else>
      <v-progress-linear v-if="isLoading" indeterminate color="primary" absolute />
      <template v-else-if="item">
        <div style="position: sticky; top: 64px; z-index: 1">
          <v-toolbar flat>
            <v-toolbar-title>
              <router-link
                :to="{ name: 'siteShow', params: { id: $iriToId(item.site['@id']) } }"
                class="text-decoration-none"
              >
                {{ item.site.name }}
              </router-link>
              <v-icon size="20">mdi-chevron-right</v-icon>
              {{ item.name }}
            </v-toolbar-title>
            <v-spacer />
            <div>
              <v-tabs v-model="selectedDateRange" height="65" background-color="transparent">
                <v-tabs-slider color="blue"></v-tabs-slider>

                <v-tab v-for="item in dateRangeItems" :key="item.value" :href="'#' + item.value">
                  {{ item.text }}
                </v-tab>
              </v-tabs>
            </div>
          </v-toolbar>

          <v-divider />
        </div>

        <template v-if="item">
          <ObservationList :date-range="dateRange" :observed-property="item" @reload="reload" />
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import AppToolbar from '@/components/AppToolbar'
import ObservationList from '@/components/Observation/List'
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import ShowMixin from 'usail-vue-sdk/mixins/ShowMixin'
import { subDays, addDays, subHours } from 'date-fns'
import PageNotFound from 'usail-vue-sdk/components/error/PageNotFound'
import GenericError from 'usail-vue-sdk/components/error/GenericError'

export default {
  name: 'ObservedPropertyShow',
  servicePrefix: 'ObservedProperty',
  endpoint: 'observed_properties',
  components: {
    ObservationList,
    AppToolbar,
    PageNotFound,
    GenericError
  },
  mixins: [ShowMixin],

  data() {
    return {
      selectedDateRange: 'last_hour',
      dateRangeItems: [
        { text: this.$t('message.last_hour'), value: 'last_hour' },
        { text: this.$t('message.today'), value: 'today' },
        { text: this.$t('message.yesterday'), value: 'yesterday' },
        { text: this.$t('message.last_week'), value: 'last_week' }
      ]
    }
  },
  computed: {
    ...mapFields('observedProperty', {
      item: 'retrieved',
      isLoading: 'isLoading',
      error: 'error'
    }),
    ...mapGetters('observedProperty', ['find']),

    dateRange() {
      const now = new Date()
      const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
      const todayEnd = addDays(todayStart, 1)

      switch (this.selectedDateRange) {
        case 'last_hour':
          return [subHours(now, 1), now]
        case 'today':
          return [todayStart, todayEnd]
        case 'yesterday':
          return [subDays(todayStart, 1), todayStart]
        case 'last_week':
          return [subDays(todayStart, 7), todayEnd]
        default:
          return [todayStart, todayEnd]
      }
    }
  },

  beforeRouteUpdate(to, from, next) {
    this.retrieve('/observed_properties/' + to.params.id)
    next()
  },

  methods: {
    ...mapActions('observedProperty', {
      deleteItem: 'del',
      reset: 'resetShow',
      retrieve: 'load'
    }),

    reload() {
      this.retrieve('/observed_properties/' + this.$route.params.id)
    }
  }
}
</script>
