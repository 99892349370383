<template>
  <div class="fill-height">
    <v-progress-linear v-if="isLoading" indeterminate color="primary" absolute />
    <v-row no-gutters class="fill-height">
      <v-col v-if="observedProperty.chartType" cols="12" md="8" xl="9" class="border-right">
        <div style="position: sticky; top: 129px">
          <ObservationsSummary
            :observed-property="observedProperty"
            :observations="observations"
            class="pa-4"
          ></ObservationsSummary>
          <ObservationLineChart
            v-if="'line' === observedProperty.chartType"
            class="mr-2"
            :observed-property="observedProperty"
            :observations="observations"
          />
          <ObservationBarChart
            v-if="'bar' === observedProperty.chartType"
            class="mr-2"
            :observed-property="observedProperty"
            :observations="observations"
          />
          <ObservationStatusChart
            v-else-if="'status' === observedProperty.chartType"
            :observed-property="observedProperty"
            :observations="observations"
            class="mr-3"
          />
          <ObservationPieChart
            v-else-if="'pie' === observedProperty.chartType"
            :observed-property="observedProperty"
            :observations="observations"
            :is-loading="isLoading"
            class="mr-3"
          />
        </div>
      </v-col>
      <v-col class="white">
        <template v-if="'decimal' === observedProperty.type || 'integer' === observedProperty.type">
          <ThresholdShow :observed-property="observedProperty" @reload="$emit('reload')" />
          <v-divider />
        </template>

        <div v-if="!observedProperty.minThreshold && !observedProperty.maxThreshold" class="title pa-4 pb-3">
          {{ $t('message.observations') }}
        </div>
        <v-tabs
            v-else
            v-model="listTab"
            centered
            icons-and-text
            fixed-tabs
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#observations">
            {{ $t('message.observations') }}
            <v-icon>mdi-chart-timeline-variant</v-icon>
          </v-tab>

          <v-tab href="#alerts">
            {{ $t('message.alerts') }}
            <v-icon>mdi-alert</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="listTab">
          <v-tab-item value="observations">
            <v-data-table
                :headers="headers"
                :items="observations"
                :items-per-page.sync="options.itemsPerPage"
                :options="options"
                :no-data-text="$t('message.no_observations')"
                :loading-text="$t('message.loading')"
                item-key="@id"
                style="width: 100%;"
                class="row-pointer"
                :footer-props="{ 'items-per-page-options': [25, 50, 100] }"
                :hide-default-footer="observations.length <= 50"
            >
              <template slot="item.observedAt" slot-scope="{ item }">
                {{ utcformat(new Date(item.observedAt), 'Ppp') }}
              </template>
              <template slot="item.result" slot-scope="{ item }">
            <span :class="{ 'red--text': null != getAlert(item.result) }">
              <v-icon v-if="null != getAlert(item.result)" color="red" size="16" style="margin-top: -2px">
                <template v-if="'max' === getAlert(item.result)">mdi-arrow-up</template>
                <template v-else>mdi-arrow-down</template>
              </v-icon>
              {{ item.result | humanizeResult(observedProperty.type) }} {{ observedProperty.units }}
            </span>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item value="alerts">
            <Alerts :observed-property="observedProperty" :date-range="dateRange" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ObservationLineChart from '@/components/Observation/Chart/Line'
import ObservationStatusChart from '@/components/Observation/Chart/Status'
import { format, addMinutes } from 'date-fns'
import ObservationsSummary from '@/components/Observation/Summary'
import ThresholdShow from '@/components/ObservedProperty/Threshold/Show'
import ObservationPieChart from '@/components/Observation/Chart/Pie'
import ObservationBarChart from '@/components/Observation/Chart/Bar'
import Alerts from "@/components/Observation/Alerts";

export default {
  name: 'ObservationList',
  components: {
    Alerts,
    ObservationBarChart,
    ObservationPieChart,
    ThresholdShow,
    ObservationsSummary,
    ObservationStatusChart,
    ObservationLineChart
  },

  props: {
    dateRange: {
      type: Array,
      required: true
    },
    observedProperty: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      format,
      addMinutes,
      listTab: 'observations',
      options: {
        sortBy: ['observedAt'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 50
      },
      headers: [
        { text: this.$t('message.observed_at'), value: 'observedAt', sortable: true },
        { text: this.$t('message.result'), value: 'result', sortable: true, align: 'right' }
      ],
      filters: {
        observedProperty: this.observedProperty['@id'],
        pagination: false,
        'observedAt[after]': this.dateRange[0].toISOString(),
        'observedAt[before]': this.dateRange[1].toISOString(),
        'order[observedAt]': 'asc'
      },

      observations: [],
      isLoading: false
    }
  },

  watch: {
    dateRange() {
      this.setFilters()
    },

    filters: {
      handler() {
        this.fetchObservations()
      },
      deep: true
    }
  },

  mounted() {
    this.fetchObservations()
  },

  methods: {
    fetchObservations() {
      let query = '?'
      Object.entries(this.filters).forEach(([key, value], index) => {
        if (index !== 0) {
          query += '&'
        }

        query += key + '=' + value
      })

      this.isLoading = true
      this.$api
        .fetch('/observations' + query)
        .then(response => response.json())
        .then(data => {
          this.observations = data['hydra:member']
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log(e)
        })
        .finally(() => (this.isLoading = false))
    },

    utcformat(date, format) {
      return this.format(this.addMinutes(date, date.getTimezoneOffset()), format)
    },

    setFilters() {
      this.$set(this.filters, 'observedAt[after]', this.dateRange[0].toISOString())
      this.$set(this.filters, 'observedAt[before]', this.dateRange[1].toISOString())
    },

    getAlert(result) {
      if (this.observedProperty.maxThreshold && result > this.observedProperty.maxThreshold) {
        return 'max'
      } else if (this.observedProperty.minThreshold && result < this.observedProperty.minThreshold) {
        return 'min'
      }

      return null
    }
  }
}
</script>
