<template>
  <div v-if="observations.length > 0">
    <v-row align="center">
      <v-col>
        <v-card outlined class="summary-card">
          <v-card-text class="py-1 px-3">
            <div class="grey--text text--darken-1 text-no-wrap caption">{{ $t('message.last_result') }}</div>
            <div class="text-no-wrap subtitle-1">
              {{ observations[0].result | humanizeResult(observedProperty.type) }} {{ observedProperty.units }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined class="summary-card">
          <v-card-text class="py-1 px-3">
            <div class="grey--text text--darken-1 text-no-wrap caption">{{ $t('message.observations_count') }}</div>
            <div class="title text-no-wrap subtitle-1">{{ observations.length }}</div>
          </v-card-text>
        </v-card>
      </v-col>

      <template v-if="'decimal' === observedProperty.type || 'integer' === observedProperty.type">
        <v-col>
          <v-card outlined class="summary-card">
            <v-card-text class="py-1 px-3">
              <div class="grey--text text--darken-1 text-no-wrap caption">{{ $t('message.alerts_count') }}</div>
              <div class="title text-no-wrap subtitle-1">{{ alertsCount }}</div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col>
          <v-card outlined class="summary-card">
            <v-card-text class="py-1 px-3">
              <div class="grey--text text--darken-1 text-no-wrap caption">{{ $t('message.max_result') }}</div>
              <div class="title text-no-wrap subtitle-1">
                {{ maxResult | humanizeResult(observedProperty.type) }} {{ observedProperty.units }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined class="summary-card">
            <v-card-text class="py-1 px-3">
              <div class="grey--text text--darken-1 text-no-wrap caption">{{ $t('message.min_result') }}</div>
              <div class="title text-no-wrap subtitle-1">
                {{ minResult | humanizeResult(observedProperty.type) }} {{ observedProperty.units }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined class="summary-card">
            <v-card-text class="py-1 px-3">
              <div class="grey--text text--darken-1 text-no-wrap caption">{{ $t('message.result_average') }}</div>
              <div class="title text-no-wrap subtitle-1">
                {{ resultAverage | humanizeResult('decimal') }} {{ observedProperty.units }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ObservationsSummary',
  props: {
    observedProperty: {
      type: Object,
      required: true
    },

    observations: {
      type: Array,
      required: true
    }
  },

  computed: {
    alertsCount() {
      return this.observations.filter(observation => {
        return (
          (this.observedProperty.minThreshold && observation.result < this.observedProperty.minThreshold) ||
          (this.observedProperty.maxThreshold && observation.result > this.observedProperty.maxThreshold)
        )
      }).length
    },

    maxResult() {
      let observations = JSON.parse(JSON.stringify(this.observations))
      return observations.sort((a, b) => {
        return b.result - a.result
      })[0].result
    },

    minResult() {
      let observations = JSON.parse(JSON.stringify(this.observations))
      return observations.sort((a, b) => {
        return a.result - b.result
      })[0].result
    },

    resultAverage() {
      let sum = 0
      this.observations.forEach(observation => {
        sum += Number(observation.result)
      })
      return sum / this.observations.length
    }
  }
}
</script>

<style lang="scss" scoped>
.summary-card {
  background-color: #fafafa !important;
}
</style>
