<template>
  <apexchart v-if="observations.length > 0" :height="chartHeight" type="bar" :series="series" :options="options" />
  <v-row v-else no-gutters align="center" justify="center" class="fill-height" :style="{ height: chartHeight + 'px' }">
    <div class="text-center">
      <v-icon size="150" color="grey lighten-2">mdi-chart-line</v-icon>
      <div class="headline">
        {{ $t('message.no_observations') }}
      </div>
    </div>
  </v-row>
</template>

<script>
import { format, differenceInHours } from 'date-fns'
export default {
  name: 'ObservationBarChart',
  props: {
    observedProperty: {
      type: Object,
      required: true
    },

    observations: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      windowHeight: window.innerHeight,
      chartHeight: window.innerHeight - 260
    }
  },

  computed: {
    options() {
      if (this.observations.length === 0) {
        return
      }

      const units = this.observedProperty.units

      let categories = []
      this.observations.forEach(observation => {
        categories.push(new Date(observation.observedAt).getTime())
      })

      let startDate = new Date(this.observations[0].observedAt)
      let endDate = new Date(this.observations[this.observations.length - 1].observedAt)

      let isHourChart = differenceInHours(endDate, startDate) <= 1

      let options = {
        chart: {
          animations: {
            enabled: false
          }
        },

        grid: {
          strokeDashArray: 5,
          xaxis: {
            lines: {
              show: isHourChart
            }
          }
        },

        colors: ['#7E57C2'],

        dataLabels: {
          enabled: isHourChart
        },

        plotOptions: {
          bar: {
            columnWidth: isHourChart ? '55%' : '100%'
          }
        },

        xaxis: {
          tickAmount: isHourChart ? undefined : 9,
          categories: categories,
          labels: {
            formatter: function(value) {
              return format(value, 'HH:mm')
            },
            rotate: false,
            offsetX: isHourChart ? 0 : window.innerWidth / 60
          }
        },

        tooltip: {
          x: {
            formatter: function(val, opts) {
              return format(val, 'Ppp')
            }
          },
          y: {
            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
              return value + ' ' + units
            }
          }
        }
      }

      if (this.observedProperty.minThreshold || this.observedProperty.maxThreshold) {
        options.annotations = {
          yaxis: []
        }

        if (this.observedProperty.minThreshold) {
          options.annotations.yaxis.push({
            y: this.observedProperty.minThreshold,
            borderColor: '#F44336',
            strokeDashArray: 5,
            label: {
              borderColor: '#F44336',
              style: {
                color: '#fff',
                background: '#F44336'
              },
              text: this.$t('message.min_threshold')
            }
          })
        }

        if (this.observedProperty.maxThreshold) {
          options.annotations.yaxis.push({
            y: this.observedProperty.maxThreshold,
            borderColor: '#F44336',
            strokeDashArray: 5,
            label: {
              borderColor: '#F44336',
              style: {
                color: '#fff',
                background: '#F44336'
              },
              text: this.$t('message.max_threshold')
            }
          })
        }
      }

      return options
    },

    series() {
      if (this.observations.length === 0) {
        return
      }

      let data = []
      this.observations.forEach(observation => {
        data.push(Number(observation.result))
      })

      return [
        {
          name: this.$t('message.result'),
          data: data
        }
      ]
    }
  },

  watch: {
    windowHeight(newHeight) {
      this.chartHeight = newHeight - 260
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  methods: {
    onResize() {
      this.windowHeight = window.innerHeight
    }
  }
}
</script>
