<template>
  <v-data-table
    :headers="headers"
    :items="alerts"
    :items-per-page.sync="options.itemsPerPage"
    :options="options"
    :no-data-text="$t('message.no_alerts')"
    :loading="isLoading"
    :loading-text="$t('message.loading')"
    item-key="@id"
    style="width: 100%;"
    class="row-pointer"
    :footer-props="{ 'items-per-page-options': [25, 50, 100] }"
    :hide-default-footer="alerts.length <= 50"
  >
    <template slot="item.raisedAt" slot-scope="{ item }">
      {{ utcformat(new Date(item.raisedAt), 'Ppp') }}
    </template>
    <template slot="item.causedBy.result" slot-scope="{ item }">
      <span :class="{ 'green--text': 'recovery' === item.type, 'red--text': 'recovery' !== item.type }">
        <v-icon :color="'recovery' === item.type ? 'green' : 'red'" size="16" style="margin-top: -2px">
          <template v-if="'recovery' === item.type">mdi-check</template>
          <template v-else-if="'over-max' === item.type">mdi-arrow-up</template>
          <template v-else>mdi-arrow-down</template>
        </v-icon>
        {{ item.causedBy.result | humanizeResult(observedProperty.type) }} {{ observedProperty.units }}
      </span>
    </template>
  </v-data-table>
</template>

<script>
import { format, addMinutes } from 'date-fns'

export default {
  name: 'Alerts',
  props: {
    dateRange: {
      type: Array,
      required: true
    },
    observedProperty: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      format,
      addMinutes,
      options: {
        sortBy: ['raisedAt'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 50
      },
      headers: [
        { text: 'Raised At', value: 'raisedAt', sortable: true },
        { text: 'Result', value: 'causedBy.result', sortable: true, align: 'right' }
      ],
      filters: {
        'causedBy.observedProperty': this.observedProperty['@id'],
        pagination: false,
        'raisedAt[after]': this.dateRange[0].toISOString(),
        'raisedAt[before]': this.dateRange[1].toISOString(),
        'order[raisedAt]': 'desc'
      },
      alerts: [],
      isLoading: false
    }
  },

  watch: {
    dateRange() {
      this.setFilters()
    },

    filters: {
      handler() {
        this.fetchAlerts()
      },
      deep: true
    }
  },

  mounted() {
    this.fetchAlerts()
  },

  methods: {
    setFilters() {
      this.$set(this.filters, 'raisedAt[after]', this.dateRange[0].toISOString())
      this.$set(this.filters, 'raisedAt[before]', this.dateRange[1].toISOString())
    },

    fetchAlerts() {
      let query = '?'
      Object.entries(this.filters).forEach(([key, value], index) => {
        if (index !== 0) {
          query += '&'
        }

        query += key + '=' + value
      })

      this.isLoading = true
      this.$api
        .fetch('/alerts' + query)
        .then(response => response.json())
        .then(data => {
          this.alerts = data['hydra:member']
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log(e)
        })
        .finally(() => (this.isLoading = false))
    },

    utcformat(date, format) {
      return this.format(this.addMinutes(date, date.getTimezoneOffset()), format)
    },
  }
}
</script>
