<template>
  <v-dialog v-model="dialog" width="400">
    <v-form v-model="valid" lazy-validation @submit.prevent="onSubmit(item)">
      <v-card>
        <v-card-title>{{ $t('message.edit_thresholds_settings') }}</v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field v-model="item.minThreshold" clearable :label="$t('message.min_threshold')" />
            </v-col>
            <v-col>
              <v-text-field v-model="item.maxThreshold" clearable :label="$t('message.max_threshold')" />
            </v-col>
          </v-row>

          <v-switch v-model="emailSwitch" :label="$t('message.notify_alerts_via_email')" hide-details></v-switch>
          <v-text-field v-if="emailSwitch" v-model="item.email" :label="$t('message.email')" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text color="default" @click="dialog = false">{{ $t('message.cancel') }}</v-btn>
          <v-btn text :disabled="!valid || !updated || isLoading" type="submit" color="primary" depressed>{{
            $t('message.save')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'ThresholdUpdate',

  props: {
    value: {
      type: Boolean,
      required: true
    },
    observedProperty: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      item: {
        minThreshold: this.observedProperty.minThreshold,
        maxThreshold: this.observedProperty.maxThreshold,
        email: this.observedProperty.email
      },
      valid: true,
      isLoading: false,
      emailSwitch: !!this.observedProperty.email
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },

    updated() {
      return (
        this.item.minThreshold !== this.observedProperty.minThreshold ||
        this.item.maxThreshold !== this.observedProperty.maxThreshold ||
        this.item.email !== this.observedProperty.email
      )
    }
  },

  watch: {
    emailSwitch(value) {
      this.item.email = true === value ? this.observedProperty.email : null
    }
  },

  methods: {
    onSubmit(item) {
      this.isLoading = true

      if (item.minThreshold) {
        item.minThreshold = item.minThreshold.toString()
      }

      if (item.maxThreshold) {
        item.maxThreshold = item.maxThreshold.toString()
      }

      if ('' === item.minThreshold) {
        item.minThreshold = null
      }

      if ('' === item.maxThreshold) {
        item.maxThreshold = null
      }

      this.$api
        .fetch(this.observedProperty['@id'], {
          method: 'PUT',
          headers: new Headers({ 'Content-Type': 'application/ld+json' }),
          body: JSON.stringify(item)
        })
        .then(response => response.json())
        .then(() => {
          this.dialog = false
          this.$emit('reload')
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
